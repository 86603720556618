import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/components/alert/alert.service";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-my-campaign-overview",
  templateUrl: "./my-campaign-overview.component.html",
  styleUrls: ["./my-campaign-overview.component.css"],
})
export class MyCampaignOverviewComponent implements OnInit {
  uid: string;
  loading: boolean = true;
  isSubmitting: boolean = false;
  campaign: any;
  leadUrl: string = environment.leadUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get("uid");
    this.loadCampaign();
  }

  loadCampaign() {
    this.loading = true;
    this.apiService.getCampaign(this.uid).subscribe(
      (data: any) => {
        this.campaign = data;
        this.loading = false;
        if (this.campaign == null) {
          this.alertService.showAlert("warning", "No Campaign found!");
        } else {
          this.alertService.showAlert(
            "success",
            "Campaign loaded successfully"
          );
        }
      },
      (error) => {
        console.error("Error fetching campaigns", error);
        this.loading = false;
        this.alertService.showAlert(
          "error",
          "Error fetching campaign. Please try again later."
        );
      }
    );
  }

  getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case "assigned":
        return "badge badge-secondary";
      case "submitted":
        return "badge badge-info";
      case "approved":
        return "badge badge-success";
      case "rejected":
        return "badge badge-danger";
      default:
        return "";
    }
  }

  canSubmit(): boolean {
    if (
      this.campaign.status !== "assigned" &&
      this.campaign.status !== "rejected"
    ) {
      return false;
    }
    return this.campaign.campaignAccountChannel.every(
      (channel) => channel.campaignAccountChannelAds.length > 0
    );
  }

  getTooltipMessage(): string {
    if (
      this.campaign.status !== "assigned" &&
      this.campaign.status !== "rejected"
    ) {
      return 'Cannot submit because the campaign status is not "assigned" or "rejected".';
    }
    if (
      !this.campaign.campaignAccountChannel.every(
        (channel) => channel.campaignAccountChannelAds.length > 0
      )
    ) {
      return "Cannot submit because some channels have no ads.";
    }
    return "Ready to submit.";
  }

  submitCampaign() {
    this.isSubmitting = true;
    this.apiService.submitCampaign(this.uid).subscribe(
      (response) => {
        this.alertService.showAlert(
          "success",
          "Campaign submitted successfully!"
        );
        this.loadCampaign();
        this.isSubmitting = false;
      },
      (error) => {
        console.error("Error submitting campaign", error);
        if (error?.error.message) {
          console.log(error?.error.statusCode);
          const alert = error?.error.statusCode == 406 ? "warning" : "error";
          this.alertService.showAlert(alert, error?.error.message);
        } else {
          this.alertService.showAlert(
            "error",
            "Error submitting campaign. Please try again later."
          );
        }
        this.isSubmitting = false;
      }
    );
  }

  goBack() {
    this.router.navigate(["/home"]);
  }

  goToAds() {
    this.router.navigate(["my-campaign", this.uid, "ads"]);
  }

  copyText(text: string) {
    const textarea = document.createElement("textarea");
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      this.alertService.showAlert("success", "Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text", err);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}
