import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginpageComponent } from "./pages/loginpage/loginpage.component";
import { ErrorComponent } from "./pages/error/error.component";
import { Error500Component } from "./pages/500error/500error.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { AuthGuard } from "./auth.guard";
import { MyCampaignComponent } from "./pages/my-campaign/my-campaign.component";
import { MyCampaignAdsComponent } from "./pages/my-campaign-ads/my-campaign-ads.component";
import { MyCampaignOverviewComponent } from "./pages/my-campaign-overview/my-campaign-overview.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginpageComponent },
  { path: "error", component: ErrorComponent },
  { path: "500-error", component: Error500Component },
  { path: "home", component: HomepageComponent, canActivate: [AuthGuard] },
  {
    path: "my-campaign/:uid",
    component: MyCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-campaign/:uid/ads",
    component: MyCampaignAdsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-campaign/:uid/overview",
    component: MyCampaignOverviewComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
