import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUrl = environment.apiUrl + "/campaign";
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private router: Router) {
    const token = localStorage.getItem("access_token");
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  getCampaigns(): Observable<any> {
    return this.http.get(`${this.apiUrl}/my-campaigns`, {
      headers: this.headers,
    });
  }

  getCampaign(uid: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/my-campaign/${uid}`, {
      headers: this.headers,
    });
  }

  saveCampaignDraft(uid: string, data: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/my-campaign/${uid}/save-draft`,
      data,
      {
        headers: this.headers,
      }
    );
  }
  
  submitCampaign(uid: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/my-campaign/${uid}/submit`, null, {
      headers: this.headers,
    });
  }
}
