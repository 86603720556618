<nav class="navbar navbar-main bg-default navbar-expand-lg navbar-dark headroom" id="navbar-main">
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/home']">
      <img src="assets/img/white-big-logo.png" id="brand-logo" />
    </a>
    <button aria-controls="navbar_global" aria-label="Toggle navigation" class="navbar-toggler"
      [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navbar_global" type="button">
      <span class="navbar-toggler-icon"> </span>
    </button>
    <div class="navbar-collapse" [isAnimated]="true" [collapse]="isCollapsed" id="navbar_global">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/index']">
              <img src="assets/img/sitelogo-almajdouie.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button aria-controls="navbar_global" aria-label="Toggle navigation" class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navbar_global" type="button">
              <span> </span> <span> </span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item dropdown" *ngIf="!mobileView()">
          <a class="nav-link" href="javascript:;" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="ni ni-circle-08 t-5" style="font-size: 1.5rem;"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="javascript:;">
              Welcome, {{ user.name }}!
            </a>
            <a class="dropdown-item" href="javascript:;">
            {{ user.email }}
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item p-0 m-0" href="javascript:;" (click)="logout()"> <a class="btn btn-danger w-100 h-100" href="">Logout</a></a>
          </div>
        </li>
        <li class="nav-item" *ngIf="mobileView()">
          <a class="nav-link" href="javascript:;">
            Welcome, {{ user.name }}!
          </a>
          <a class="nav-link" href="javascript:;">
            {{ user.email }}
          </a>
          <a class="nav-link btn btn-danger" style="color: white !important;" href="javascript:;" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>