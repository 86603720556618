import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/components/alert/alert.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-my-campaign-ads",
  templateUrl: "./my-campaign-ads.component.html",
  styleUrls: ["./my-campaign-ads.component.css"],
})
export class MyCampaignAdsComponent implements OnInit {
  uid: string;
  loading: boolean = true;
  savingDraft: boolean = false;
  campaign: any;
  selectedChannelUid: string;
  numberOfAdGroups: number;
  adGroups: any[] = [];
  numberOfAdGroupsInvalid: boolean = false;
  adGroupNamesInvalid: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get("uid");
    this.loadCampaign();
  }

  loadCampaign() {
    this.loading = true;
    this.apiService.getCampaign(this.uid).subscribe(
      (data: any) => {
        this.campaign = data;
        this.loading = false;
        if (this.campaign == null) {
          this.alertService.showAlert("warning", "No Campaign found!");
        } else if (
          this.campaign.status == "approved" ||
          this.campaign.status == "submitted"
        ) {
          this.alertService.showAlert(
            "warning",
            "Campaign ads approved or submitted can't edit, to overview"
          );
          setTimeout(() => {
            this.goToOverview();
          }, 3000);
        } else {
          this.alertService.showAlert(
            "success",
            "Campaign loaded successfully"
          );
        }
      },
      (error) => {
        console.error("Error fetching campaigns", error);
        this.loading = false;
        this.alertService.showAlert(
          "error",
          "Error fetching campaign. Please try again later."
        );
      }
    );
  }

  selectChannel(channel) {
    this.selectedChannelUid = channel.uid;
    this.adGroups = [];

    if (channel.campaignAccountChannelAds.length > 0) {
      channel.campaignAccountChannelAds.forEach((ad) => {
        let adGroup = this.adGroups.find((group) => group.name === ad.adGroup);
        if (!adGroup) {
          adGroup = {
            name: ad.adGroup,
            numberOfAds: 0,
            ads: [],
            adNamesInvalid: false,
          };
          this.adGroups.push(adGroup);
        }

        let existingAd = adGroup.ads.find((a) => a.name === ad.adName);
        if (!existingAd) {
          existingAd = {
            name: ad.adName,
            versions: [],
            numberOfVersions: 0,
          };
          adGroup.ads.push(existingAd);
        }

        existingAd.versions.push(ad.adVersion);
        existingAd.numberOfVersions = existingAd.versions.length;

        adGroup.numberOfAds = adGroup.ads.length;
      });
      this.adGroups.forEach((group) => {
        group.ads.forEach((ad) => {
          this.generateAdVersions(ad);
        });
      });
      this.numberOfAdGroups = this.adGroups.length;
    } else {
      this.setDefaultAdGroupValues();
    }
  }

  setDefaultAdGroupValues() {
    this.numberOfAdGroups = 1;
    this.adGroups = [
      {
        name: "",
        numberOfAds: 1,
        ads: [
          {
            name: "",
            numberOfVersions: 1,
            versions: [],
          },
        ],
        adNamesInvalid: false,
      },
    ];
  }

  generateAdGroups() {
    this.numberOfAdGroupsInvalid = this.numberOfAdGroups <= 0;
    if (!this.numberOfAdGroupsInvalid) {
      this.adGroups = Array.from({ length: this.numberOfAdGroups }, () => ({
        name: "",
        numberOfAds: 1,
        ads: [
          {
            name: "",
            numberOfVersions: 1,
            versions: [],
          },
        ],
        adNamesInvalid: false,
      }));
    }
  }

  generateAds(adGroup) {
    adGroup.numberOfAdsInvalid = adGroup.numberOfAds <= 0;
    if (!adGroup.numberOfAdsInvalid) {
      adGroup.ads = Array.from({ length: adGroup.numberOfAds }, () => ({
        name: "",
        numberOfVersions: 1,
        versions: [],
      }));
    }
  }

  generateAdVersions(ad) {
    ad.versions = Array.from({ length: ad.numberOfVersions }, (_, index) => ({
      name: ad.name,
      version: (index + 1).toString(),
    }));
  }

  validateAdGroupNames() {
    const names = this.adGroups
      .map((adGroup) => adGroup.name)
      .filter((name) => name.trim() !== "");
    this.adGroupNamesInvalid = new Set(names).size !== names.length;
  }

  validateAdNames(adGroup) {
    const names = adGroup.ads
      .map((ad) => ad.name)
      .filter((name) => name.trim() !== "");
    adGroup.adNamesInvalid = new Set(names).size !== names.length;
  }

  allAdGroupsValid() {
    return this.adGroups.every(
      (adGroup) => adGroup.name.trim() !== "" && adGroup.numberOfAds > 0
    );
  }

  allAdsValid() {
    return this.adGroups.every((adGroup) => {
      return (
        adGroup.ads.every((ad) => {
          const hasValidName = ad.name ? ad.name.trim() !== "" : false;
          const hasValidVersions = ad.versions && ad.versions.length > 0;
          return hasValidName && hasValidVersions;
        }) && !adGroup.adNamesInvalid
      );
    });
  }

  saveDraft() {
    this.savingDraft = true;

    const campaignAccountChannelAds = this.adGroups.flatMap((adGroup) =>
      adGroup.ads.flatMap((ad) =>
        ad.versions.map((version) => ({
          adGroup: adGroup.name,
          adName: ad.name,
          adVersion: version.version,
        }))
      )
    );

    const output = {
      campaignAccountChannelUid: this.selectedChannelUid,
      campaignAccountChannelAds,
    };

    this.apiService.saveCampaignDraft(this.uid, output).subscribe(
      (response) => {
        this.alertService.showAlert("success", "Draft saved successfully!");
        this.loadCampaign();
        this.savingDraft = false;
      },
      (error) => {
        console.error("Error saving draft", error);
        this.alertService.showAlert(
          "error",
          "Error saving draft. Please try again later."
        );
        this.savingDraft = false;
      }
    );
  }

  goBack() {
    this.router.navigate(["/home"]);
  }

  goToOverview() {
    this.router.navigate(["my-campaign", this.uid, "overview"]);
  }
}
