import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService } from "src/app/components/alert/alert.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"],
})
export class HomepageComponent implements OnInit {
  campaigns: any[] = [];
  loading: boolean = true;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("contact-page");

    var navbar = document.getElementById("navbar-main");
    navbar.classList.add("bg-default");

    this.loadCampaigns();
  }

  loadCampaigns() {
    this.apiService.getCampaigns().subscribe(
      (data: any[]) => {
        this.campaigns = data;
        this.loading = false;
        if (this.campaigns.length == 0)
          this.alertService.showAlert("warning", "Warning No Campings found!");
        else
          this.alertService.showAlert(
            "success",
            "Success Campings loaded successfully"
          );
      },
      (error) => {
        console.error("Error fetching campaigns", error);
        this.loading = false;
        this.alertService.showAlert(
          "error",
          "Error fetching campaigns. Please try again later."
        );
      }
    );
  }
  navigateToMyCampaignAds(uid: string) {
    this.router.navigate(["my-campaign", uid, "ads"]);
  }
  navigateToMyCampaignOverview(uid: string) {
    this.router.navigate(["my-campaign", uid, "overview"]);
  }
}
