import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AlertService } from "src/app/components/alert/alert.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-my-campaign",
  templateUrl: "./my-campaign.component.html",
  styleUrls: ["./my-campaign.component.css"],
})
export class MyCampaignComponent implements OnInit {
  uid: string;
  loading: boolean = true;
  campaign: any;
  adGroupsForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private fb: FormBuilder
  ) {
    this.adGroupsForm = this.fb.group({
      channels: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get("uid");
    this.loadCampaign();
  }

  loadCampaign() {
    this.apiService.getCampaign(this.uid).subscribe(
      (data: any) => {
        this.campaign = data;
        this.loading = false;
        if (this.campaign == null) {
          this.alertService.showAlert("warning", "Warning No Campaign found!");
        } else {
          this.alertService.showAlert(
            "success",
            "Success Campaign loaded successfully"
          );
          this.initializeForms();
        }
      },
      (error) => {
        console.error("Error fetching campaigns", error);
        this.loading = false;
        this.alertService.showAlert(
          "error",
          "Error fetching campaign. Please try again later."
        );
      }
    );
  }

  initializeForms() {
    const channels = this.adGroupsForm.get("channels") as FormArray;
    this.campaign.campaignAccountChannel.forEach(() => {
      channels.push(
        this.fb.group({
          adGroups: this.fb.array([]),
        })
      );
    });
  }

  getChannels(): FormArray {
    return this.adGroupsForm.get("channels") as FormArray;
  }

  getAdGroups(channelIndex: number): FormArray {
    return this.getChannels().at(channelIndex).get("adGroups") as FormArray;
  }

  addAdGroup(channelIndex: number, adGroupCount: number) {
    const adGroups = this.getAdGroups(channelIndex);
    adGroups.clear();
    for (let i = 0; i < adGroupCount; i++) {
      adGroups.push(this.createAdGroup());
    }
  }

  createAdGroup(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      numberOfAds: ["", Validators.required],
      ads: this.fb.array([]),
    });
  }

  addAds(channelIndex: number, adGroupIndex: number, adCount: number) {
    const ads = this.getAdGroups(channelIndex)
      .at(adGroupIndex)
      .get("ads") as FormArray;
    ads.clear();
    for (let i = 0; i < adCount; i++) {
      ads.push(this.createAd());
    }
  }

  createAd(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      version: ["", Validators.required],
    });
  }

  getAdFormArray(channelIndex: number, adGroupIndex: number): FormArray {
    return this.getAdGroups(channelIndex)
      .at(adGroupIndex)
      .get("ads") as FormArray;
  }

  trackByFn(index: number): number {
    return index;
  }

  onSubmit() {
    console.log("Submitting data", this.adGroupsForm.value);
  }
}