import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  alertMessage: string;
  alertType: "success" | "error" | "warning";
  alertVisible: boolean;
  alertTimeout: any;

  showAlert(type: "success" | "error" | "warning", message: string) {
    this.alertType = type;
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertTimeout = setTimeout(() => {
      this.alertVisible = false;
      setTimeout(() => {
        this.alertMessage = null;
      }, 500);
    }, 4500);
  }

  closeAlert() {
    this.alertVisible = false;
    if (this.alertTimeout) {
      clearTimeout(this.alertTimeout);
    }
    setTimeout(() => {
      this.alertMessage = null;
    }, 500);
  }
}
