import { Component } from "@angular/core";
import { AlertService } from "./alert.service";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.css"],
})
export class AlertComponent {
  constructor(public alertService: AlertService) {}

  closeAlert() {
    this.alertService.closeAlert();
  }
}
