import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from "src/app/components/alert/alert.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-loginpage",
  templateUrl: "loginpage.component.html",
  styleUrls: ["loginpage.component.css"],
})
export class LoginpageComponent implements OnInit, OnDestroy {
  focus;
  loginForm: FormGroup;
  otpSent: boolean = false;
  loading: boolean = false;
  accountEmail?: string;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    private alertService: AlertService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      otp: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) this.router.navigate(["/home"]);
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("reset-page");
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("reset-page");
  }

  onSubmit() {
    if (this.loginForm.controls["email"].valid) {
      this.loading = true;
      this.authService.sendOtp(this.loginForm.value.email).subscribe(
        (response) => {
          this.loading = false;
          this.alertService.showAlert(
            "success",
            "OTP sent successfully. Please check your email."
          );
          this.accountEmail = this.loginForm.value.email;
          this.loginForm.controls["email"].disable();
          this.otpSent = true;
          this.animateOtpInput();
        },
        (error) => {
          this.loading = false;
          if (error.status === 400) {
            this.alertService.showAlert("error", error.error.message);
          } else {
            this.alertService.showAlert(
              "error",
              "Failed to send OTP. Please try again."
            );
          }
        }
      );
    } else {
      this.alertService.showAlert(
        "error",
        "Please enter a valid email address."
      );
    }
  }

  verifyOtp() {
    if (this.loginForm.controls["otp"].valid) {
      this.loading = true;
      this.authService
        .verifyOtp(this.accountEmail, this.loginForm.value.otp)
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.alertService.showAlert(
              "success",
              "OTP verified successfully."
            );
            this.saveToken(response.access_token);
            // Redirect to dashboard or any other page
            this.router.navigate(["/home"]);
          },
          (error) => {
            this.loading = false;
            if (error.status === 400) {
              this.alertService.showAlert("error", error.error.message);
            } else {
              this.alertService.showAlert(
                "error",
                "Failed to verify OTP. Please try again."
              );
            }
          }
        );
    } else {
      this.alertService.showAlert("error", "Please enter a valid OTP.");
    }
  }

  private saveToken(token: string) {
    localStorage.setItem("access_token", token);
  }



  private animateOtpInput() {
    const inputGroup = document.querySelector(".otp-input-group");
    if (inputGroup) {
      inputGroup.classList.add("animate__animated", "animate__fadeIn");
    }
  }
}
