<app-navbar></app-navbar>
<div class="wrapper">
    <div class="contactus-3">
        <div class="page-header mb-0">
            <!-- Removed the image tag as it's set in the CSS -->
        </div>
        <div class="container" *ngIf="!loading">
            <div class="row">
                <div class="col mr-auto ml-auto text-center mb-5">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <button class="btn btn-secondary mr-3" (click)="goBack()">Back</button>
                            <button class="btn btn-primary"
                                [disabled]="campaign.status=='approved' || campaign.status=='submitted'"
                                [tooltip]="(campaign.status == 'approved' || campaign.status == 'submitted') ? 'Campaign ads approved or submitted, can\'t edit' : ''"
                                (click)="goToAds()">Go to Ads</button>
                        </div>
                        <h3 class="display-3">{{ campaign.campaign.campaignName }}</h3>
                        <span class="badge-lg" [ngClass]="getStatusClass(campaign.status)">{{
                            campaign.status }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <span>Campaign Details</span>

                        </div>
                        <div class="card-body">
                            <p><strong>Campaign Code:</strong> {{ campaign.campaign.campaignCode }}</p>
                            <p><strong>Type:</strong> {{ campaign.campaign.type }}</p>
                            <p><strong>Category:</strong> {{ campaign.campaign.category }}</p>
                            <p><strong>Region:</strong> {{ campaign.campaign.region }}</p>
                            <p><strong>Status:</strong> {{ campaign.campaign.status }}</p>
                            <p><strong>Planned Start Date:</strong> {{ campaign.campaign.plannedStartDate | date }}</p>
                            <p><strong>Planned End Date:</strong> {{ campaign.campaign.plannedEndDate | date }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-header text-center">
                            Brand Details
                        </div>
                        <div class="card-body text-center">
                            <p><strong>Brand Name:</strong> {{ campaign.campaign.brand.name }}</p>
                            <p><strong>Brand Name (Arabic):</strong> {{ campaign.campaign.brand.name_ar }}</p>
                            <img [src]="campaign.campaign.brand.image" alt="Brand Image" class="img-fluid"
                                style="max-width: 150px; height: auto;" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <h4 class="card-title">Ads Overview</h4>
                    <table class="table table-striped mt-3">
                        <thead>
                            <tr>
                                <th>Channel</th>
                                <th>Ad Group</th>
                                <th>Ad Name</th>
                                <th>Ad Version</th>
                                <th>Ad Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let channel of campaign.campaignAccountChannel">
                                <tr *ngFor="let ad of channel.campaignAccountChannelAds">
                                    <td>{{ channel.channel.title }}</td>
                                    <td>{{ ad.adGroup }}</td>
                                    <td>{{ ad.adName }}</td>
                                    <td>{{ ad.adVersion }}</td>
                                    <td *ngIf="campaign.status == 'approved'">
                                        <a [href]="leadUrl + '?ad=' + ad.uid" target="_blank">Ad Link</a>
                                        <button class="btn btn-link" (click)="copyText(leadUrl + '?ad=' + ad.uid)">
                                            <i class="fa fa-copy"></i>
                                        </button>
                                    </td>

                                </tr>
                                <tr *ngIf="channel.campaignAccountChannelAds.length === 0" class="table-warning">
                                    <td>{{ channel.channel.title }}</td>
                                    <td colspan="4" class="text-center">No ads available for this channel.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="text-right mb-4">
                <button class="btn btn-primary" [disabled]="!canSubmit() || isSubmitting" (click)="submitCampaign()"
                    [tooltip]="getTooltipMessage()" placement="top">
                    <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="!isSubmitting">Submit</span>
                </button>
            </div>
        </div>

        <div class="container">
            <!-- Display spinner while loading -->
            <div *ngIf="loading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>