<div *ngIf="alertService.alertMessage" class="alert alert-dismissible fade show alert-custom" [ngClass]="{
  'alert-success': alertService.alertType === 'success',
  'alert-danger': alertService.alertType === 'error',
  'alert-warning': alertService.alertType === 'warning',
  'fade-in': alertService.alertVisible,
  'fade-out': !alertService.alertVisible
}" role="alert">
    <span class="alert-inner--icon">
        <i class="ni " [ngClass]="{
  'ni-like-2': alertService.alertType === 'success',
  'ni-bell-55': alertService.alertType === 'warning',
  'ni-support-16': alertService.alertType === 'error'}"></i>
    </span>
    <span class="alert-inner--text"><strong>{{ alertService.alertType | titlecase }}!</strong> {{
        alertService.alertMessage }}</span>
    <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>