import { TagInputModule } from "ngx-chips";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PresentationModule } from "./presentation/presentation.module";

import { LoginpageComponent } from "./pages/loginpage/loginpage.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { AlertComponent } from "./components/alert/alert.component";
import { JwtModule } from "@auth0/angular-jwt";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { MyCampaignComponent } from './pages/my-campaign/my-campaign.component';
import { ArchwizardModule } from "angular-archwizard";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MyCampaignAdsComponent } from "./pages/my-campaign-ads/my-campaign-ads.component";
import { MyCampaignOverviewComponent } from './pages/my-campaign-overview/my-campaign-overview.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginpageComponent,
    NavbarComponent,
    FooterComponent,
    AlertComponent,
    HomepageComponent,
    MyCampaignComponent,
    MyCampaignAdsComponent,
    MyCampaignOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    PresentationModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    ArchwizardModule,
    NgxDatatableModule,
    
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function tokenGetter() {
  return localStorage.getItem("access_token");
}
