<app-navbar></app-navbar>
<div class="wrapper">
    <div class="contactus-3">
        <div class="page-header">
            <img alt="" class="bg-image" src="assets/img/ill/bg_contactus3.svg" />
        </div>
        <div class="container" *ngIf="!loading">
            <div class="row">
                <div class="col-lg-8 mr-auto ml-auto text-center mb-5">
                    <h3 class="display-3">{{ campaign.campaign.campaignName }}</h3>
                </div>
            </div>
        </div>

        <div class="container">
            <!-- Display spinner while loading -->
            <div *ngIf="loading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <!-- Display wizard once loaded -->
            <div *ngIf="!loading" class="wizard-container">
                <aw-wizard>
                    <!-- Step 1: Show all available channels -->
                    <aw-wizard-step stepTitle="Available Channels">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Available Channels</h4>
                            </div>
                            <div class="card-body">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let channel of campaign.campaignAccountChannel; let i = index">
                                        {{ channel.channel.title }}
                                    </li>
                                </ul>
                            </div>
                            <div class="card-footer text-right">
                                <button awNextStep class="btn btn-primary">Next</button>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <!-- Dynamic steps for each channel -->
                    <ng-container *ngFor="let channel of campaign.campaignAccountChannel; let i = index; trackBy: trackByFn">
                        <!-- Step 2: Number of ad groups for each channel -->
                        <aw-wizard-step [stepTitle]="'Number of Ad Groups for ' + channel.channel.title">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Number of Ad Groups for {{ channel.channel.title }}</h4>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Number of Ad Groups</label>
                                        <input type="number" class="form-control" (change)="addAdGroup(i, $event.target.value)">
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button awNextStep class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <!-- Step 3: Ad group names and number of ads -->
                        <aw-wizard-step [stepTitle]="'Ad Group Names and Number of Ads for ' + channel.channel.title">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Ad Group Names and Number of Ads for {{ channel.channel.title }}</h4>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="adGroupsForm">
                                        <div formArrayName="channels">
                                            <div [formGroupName]="i" *ngFor="let adGroup of getAdGroups(i).controls; let j = index">
                                                <div formArrayName="adGroups">
                                                    <div [formGroupName]="j">
                                                        <div class="form-group">
                                                            <label>Ad Group Name</label>
                                                            <input type="text" formControlName="name" class="form-control">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Number of Ads in Group</label>
                                                            <input type="number" class="form-control" (change)="addAds(i, j, $event.target.value)">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="card-footer text-right">
                                    <button awNextStep class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <!-- Step 4: Ad names and version numbers -->
                        <aw-wizard-step [stepTitle]="'Ad Names and Version Numbers for ' + channel.channel.title">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Ad Names and Version Numbers for {{ channel.channel.title }}</h4>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="adGroupsForm">
                                        <div formArrayName="channels">
                                            <div [formGroupName]="i" *ngFor="let adGroup of getAdGroups(i).controls; let j = index">
                                                <div formArrayName="adGroups">
                                                    <div [formGroupName]="j">
                                                        <div formArrayName="ads" *ngFor="let ad of getAdFormArray(i, j).controls; let k = index">
                                                            <div [formGroupName]="k">
                                                                <div class="form-group">
                                                                    <label>Ad Name</label>
                                                                    <input type="text" formControlName="name" class="form-control">
                                                                </div>
                                                                <div class="form-group">
                                                                     <label>Ad Version</label>
                                                                    <input type="text" formControlName="version" class="form-control">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="card-footer text-right">
                                    <button awNextStep class="btn btn-primary">Next</button>
                                </div>
                            </div>
                        </aw-wizard-step>
                    </ng-container>

                    <!-- Step 5: Review and submit -->
                <!-- Step 5: Review and submit -->
                <aw-wizard-step stepTitle="Review and Submit">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Review and Submit</h4>
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Channel</th>
                                        <th>Ad Group</th>
                                        <th>Ad Name</th>
                                        <th>Ad Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let channel of campaign.campaignAccountChannel; let i = index">
                                        <ng-container *ngFor="let adGroup of getAdGroups(i).controls; let j = index">
                                            <ng-container *ngFor="let ad of getAdFormArray(i, j).controls; let k = index">
                                                <tr>
                                                    <td>{{ channel.channel.title }}</td>
                                                    <td>{{ getAdGroups(i).at(j).get('name').value }}</td>
                                                     <td>{{ ad.get('name').value }}</td>
                                                    <td>{{ ad.get('version').value }}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-success" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </aw-wizard-step>


                </aw-wizard>
            </div>
            <footer class="mt-5"></footer>
        </div>
    </div>
</div>
