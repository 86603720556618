<footer class="footer">
  <div class="container">
    <hr />

    <div class="row align-items-center justify-content-md-between">
      <div class="col-12">
        <div class="copyright">
          © {{ date | date: "yyyy" }}
          Developed with <i class="ni ni-favourite-28"></i> by <a href="mailto:webservices@almajdouie.com">Almajdouie
            Web Team
          </a>. </div>
      </div>

    </div>
  </div>
</footer>