<div class="wrapper">
  <div class="page-header bg-default">
    <div class="page-header-image" style="background-image: url('assets/img/almajdouie-tower.jpg');"></div>
    <div class="container">
      <div class="col-lg-5 col-md-8 mx-auto">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header">
            <img alt="Card image" class="card-img" src="assets/img/almajdouie-logo.svg" />
            <h4 class="card-title text-center">Sign In</h4>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <small *ngIf="!otpSent">Enter email address to receive OTP</small>
              <small *ngIf="otpSent">Enter the OTP sent to your email</small>
            </div>
            <form role="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-3" [ngClass]="{ 'focused': focus === true }">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"></i>
                    </span>
                  </div>
                  <input class="form-control" placeholder="Email" (blur)="focus = false" (focus)="focus = true"
                    type="email" formControlName="email" [formControl]="loginForm.controls['email']" />
                </div>
                <div
                  *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
                  class="error-message">
                  <i class="ni ni-fat-remove"></i>
                  <small *ngIf="loginForm.controls['email'].errors?.required">
                    Email is required.
                  </small>
                  <small *ngIf="loginForm.controls['email'].errors?.email">
                    Enter a valid email.
                  </small>
                </div>
              </div>
              <div class="form-group mb-3 otp-input-group" [hidden]="!otpSent">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-lock-circle-open"></i>
                    </span>
                  </div>
                  <input class="form-control" placeholder="OTP" type="text" (blur)="focus = false"
                    (focus)="focus = true" formControlName="otp" />
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" type="submit" [disabled]="loading" *ngIf="!otpSent">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span *ngIf="!loading">Get OTP</span>
                  <span *ngIf="loading"> Loading...</span>
                </button>
                <button class="btn btn-primary my-4" type="button" (click)="verifyOtp()" [disabled]="loading"
                  *ngIf="otpSent">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span *ngIf="!loading">Verify OTP</span>
                  <span *ngIf="loading"> Loading...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>