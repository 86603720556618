import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  user: any;

  constructor(private router: Router, private authService: AuthService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isCollapsed = true;
      }
    });
  }

  ngOnInit() {
    this.user = this.authService.getUserInfo();
  }

  mobileView() {
    return window.innerWidth < 992;
  }

  logout() {
    this.authService.logout();
  }
}
