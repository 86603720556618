import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl + "/campaign/auth";

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {}

  sendOtp(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/send-otp`, { email });
  }
  verifyOtp(email: string, otp: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/verify-otp`, { email, otp });
  }
  isAuthenticated(): boolean {
    const token = localStorage.getItem("access_token");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  logout() {
    localStorage.removeItem("access_token");
    this.router.navigate(["/login"]);
  }
  getUserInfo() {
    const token = localStorage.getItem("access_token");

    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const userInfo = {
      uid: decodedToken.sub,
      name: decodedToken.name,
      email: decodedToken.email,
    };
    return userInfo;
  }
}
