<app-navbar></app-navbar>
<div class="wrapper">
    <div class="contactus-3">
        <div class="page-header">
            <img alt="" class="bg-image" src="assets/img/ill/bg_contactus3.svg" />
        </div>
        <div class="container" *ngIf="!loading">
            <div class="row">
                <div class="col mr-auto ml-auto text-center mb-5">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <button class="btn btn-secondary mr-3" (click)="goBack()">Back</button>
                            <button class="btn btn-primary" (click)="goToOverview()">Overview</button>
                        </div>
                        <h3 class="display-3">{{ campaign.campaign.campaignName }}</h3>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title text-center mb-4">Plan Your Campaign</h4>
                    <aw-wizard [navBarLayout]="'none'">
                        <aw-wizard-step>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div *ngFor="let channel of campaign.campaignAccountChannel"
                                        class="card mt-2 mb-2 p-2"
                                        [ngClass]="{'bg-success': channel.campaignAccountChannelAds.length > 0}"
                                        [style.border-radius]="'10px'" (click)="selectChannel(channel)"
                                        style="cursor: pointer;">
                                        <div class="card-body d-flex justify-content-between align-items-center p-2">
                                            <h5 class="card-title mb-0">{{ channel.channel.title }}</h5>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline{{channel.uid}}"
                                                    name="selectedChannel" [value]="channel.uid"
                                                    [(ngModel)]="selectedChannelUid" class="custom-control-input"
                                                    required>
                                                <label class="custom-control-label"
                                                    for="customRadioInline{{channel.uid}}"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <button awNextStep class="btn btn-primary"
                                    [disabled]="!selectedChannelUid">Next</button>
                            </div>
                        </aw-wizard-step>

                        <aw-wizard-step>
                            <div class="form-group">
                                <label for="numberOfAdGroups">Number of Ad Groups</label>
                                <input type="tel" id="numberOfAdGroups" class="form-control"
                                    [(ngModel)]="numberOfAdGroups" (change)="generateAdGroups()" pattern="^[1-9][0-9]*$"
                                    required>
                                <div *ngIf="numberOfAdGroupsInvalid" class="text-danger small">Enter a number > 0</div>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <button awPreviousStep class="btn btn-secondary">Previous</button>
                                <button awNextStep class="btn btn-primary"
                                    [disabled]="numberOfAdGroupsInvalid || !numberOfAdGroups">Next</button>
                            </div>
                        </aw-wizard-step>

                        <aw-wizard-step>
                            <div *ngFor="let adGroup of adGroups; let i = index" class="form-group mt-2">
                                <div class="d-flex">
                                    <div class="flex-fill mr-2">
                                        <label for="adGroupName{{i}}">Ad Group Name</label>
                                        <input type="text" id="adGroupName{{i}}" class="form-control"
                                            [(ngModel)]="adGroups[i].name" (ngModelChange)="validateAdGroupNames()"
                                            required>
                                        <div *ngIf="adGroupNamesInvalid" class="text-danger small">Unique & not empty
                                        </div>
                                    </div>
                                    <div class="flex-fill ml-2">
                                        <label for="numberOfAds{{i}}">Number of Ads</label>
                                        <input type="tel" id="numberOfAds{{i}}" class="form-control"
                                            [(ngModel)]="adGroups[i].numberOfAds" (change)="generateAds(adGroups[i])"
                                            pattern="^[1-9][0-9]*$" required>
                                        <div *ngIf="adGroups[i].numberOfAdsInvalid" class="text-danger small">Enter a
                                            number > 0</div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <button awPreviousStep class="btn btn-secondary">Previous</button>
                                <button awNextStep class="btn btn-primary"
                                    [disabled]="adGroupNamesInvalid || !allAdGroupsValid()">Next</button>
                            </div>
                        </aw-wizard-step>

                        <aw-wizard-step>
                            <div *ngFor="let adGroup of adGroups; let i = index" class="mt-4">
                                <span class="font-weight-bold">{{ adGroup.name }}</span>
                                <div *ngFor="let ad of adGroup.ads; let j = index" class="form-group mt-2">
                                    <div class="d-flex">
                                        <div class="flex-fill mr-2">
                                            <label for="adName{{i}}{{j}}">Ad Name</label>
                                            <input type="text" id="adName{{i}}{{j}}" class="form-control"
                                                [(ngModel)]="ad.name" (ngModelChange)="generateAdVersions(ad)" required>
                                        </div>
                                        <div class="flex-fill ml-2">
                                            <label for="adVersions{{i}}{{j}}">Number of Versions</label>
                                            <input type="tel" id="adVersions{{i}}{{j}}" class="form-control"
                                                [(ngModel)]="ad.numberOfVersions" (change)="generateAdVersions(ad)"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <button awPreviousStep class="btn btn-secondary">Previous</button>
                                <button awNextStep class="btn btn-primary" [disabled]="!allAdsValid()">Next</button>
                            </div>
                        </aw-wizard-step>

                        <aw-wizard-step>
                            <table class="table table-striped mt-3">
                                <thead>
                                    <tr>
                                        <th>Ad Group</th>
                                        <th>Ad Name</th>
                                        <th>Ad Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let adGroup of adGroups">
                                        <ng-container *ngFor="let ad of adGroup.ads">
                                            <ng-container *ngFor="let version of ad.versions">
                                                <tr>
                                                    <td>{{ adGroup.name }}</td>
                                                    <td>{{ ad.name }}</td>
                                                    <td>{{ version.version }}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between mt-3">
                                <button awPreviousStep class="btn btn-secondary">Previous</button>
                                <button (click)="saveDraft()" class="btn btn-primary" [disabled]="savingDraft">
                                    <span *ngIf="savingDraft" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Save Draft
                                </button>
                            </div>
                        </aw-wizard-step>
                    </aw-wizard>
                </div>
            </div>
        </div>

        <div class="container">
            <!-- Display spinner while loading -->
            <div *ngIf="loading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>