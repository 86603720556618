<app-navbar></app-navbar>
<div class="wrapper">
  <div class="contactus-3">
    <div class="page-header mb-0">
      <!-- <img alt="" class="bg-image" src="assets/img/ill/bg_contactus3.svg" /> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mr-auto ml-auto text-center mb-5">
          <h3 class="display-3">Your Active Campaigns</h3>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg bg-transparent mb-5">
      <div class="container">
        <div class="navbar-translate">
          <p>{{ campaigns.length }} campaigns found</p>
        </div>
      </div>
    </nav>
    <div class="container">
      <!-- Display spinner while loading -->
      <div *ngIf="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- Display campaigns once loaded -->
      <div class="row" *ngIf="!loading">
        <div class="col-lg-4 col-md-6" *ngFor="let campaign of campaigns">
          <div class="card">
            <div class="card-header mt-2">
              <div class="float-left">
                <i class="ni ni-book-bookmark d-inline text-info mr-1"> </i>
                <p class="d-inline">{{ campaign.campaign.campaignCode }}</p>
              </div>
              <div class="float-right">
                <button href="javascript:;" class="btn btn-primary btn-sm"
                  (click)="navigateToMyCampaignOverview(campaign.uid)">
                  <small>Overview</small>
                </button>
                <button class="btn btn-secondary btn-sm" (click)="navigateToMyCampaignAds(campaign.uid)"
                  [disabled]="campaign.status =='approved'||campaign.status == 'submitted'"
                  [tooltip]="(campaign.status == 'approved' || campaign.status == 'submitted') ? 'Campaign ads approved or submitted, can\'t edit' : ''">
                  <small>ads</small>
                </button>
              </div>
            </div>
            <div class="card-body text-center p-4">
              <a>
                <img alt="Circle image" class="img-fluid rounded-circle shadow" [src]="campaign.campaign.brand.image"
                  style="width: 100px;" />
              </a>
              <h4 class="card-title mt-3 mb-0">
                {{ campaign.campaign.campaignName }}
              </h4>
              <p class="card-description">
                {{ campaign.campaign.brand.name }}
              </p>
              <p class="card-description">
                <b>Start Date:</b> {{ campaign.campaign.plannedStartDate | date:'mediumDate' }}
              </p>
              <p class="card-description">
                <b>End Date:</b> {{ campaign.campaign.plannedEndDate | date:'mediumDate' }}
              </p>
              <p class="card-description">
                <b>Status:</b> {{ campaign.campaign.status }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer class="mt-5"></footer>
    </div>
  </div>
</div>